import React, { useState } from 'react';
import './Login.scss';
import logo from '../../Images/widelogo.png';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Eye, EyeOff } from 'react-feather';

function Login() {
  const navigate = useNavigate(); // Access the navigate function for redirection
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      toast.success('Success! You have logged in.');

      // Redirect to /dashboard
      navigate('/dashboard');
    } catch (error) {
      console.error(error);
      toast.error('Login failed. Please check your credentials.');
    }
  };

  return (
    <div id="login">
      <Link to="/login">
        <img src={logo} alt="azora" className="logo" />
      </Link>

      <div className="login-container">
        <form onSubmit={handleLogin}>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="input"
            />

            <label>Password</label>
            <div className="password-input-container">
              <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="input"
              />
              <span className="password-toggle" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <EyeOff size={16} /> : <Eye size={16} />}
              </span>
            </div>

            <button type="submit" className="btn primary">
              Login
            </button>

            <Link to="/reset">
              <button type="button" className="btn secondary">
                Forgot Password?
              </button>
            </Link>

            <p>
              Don't have an account? <Link to="/create">Sign up</Link>
            </p>
          </div>
        </form>
      </div>

      <ToastContainer position="top-right" autoClose={3000} closeOnClick draggable />
    </div>
  );
}

export default Login;
