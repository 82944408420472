import React, { useState } from 'react';
import './Login.scss';
import logo from '../../Images/widelogo.png';
import { auth } from '../../firebase';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';

function Create() {
  const navigate = useNavigate(); // Access the navigate function for redirection
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleCreateAccount = async (e) => {
    e.preventDefault();
    try {
      const auth = getAuth(); // Create the auth object
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Set user role to "signedIn"
      // You can use local storage or state management library (e.g., Redux) to store the user role
      localStorage.setItem('userRole', 'signedIn');

      // Sign in with the newly created account
      await signInWithEmailAndPassword(auth, email, password);

      // Redirect to /dashboard
      navigate('/dashboard');
    } catch (error) {
      console.error(error); // Log the error for debugging
      const errorCode = error.code;
      const errorMessage = error.message;
      if (errorCode === 'auth/email-already-in-use') {
        toast.error('Sorry! This email is already in use.');
      } else {
        toast.error(errorMessage);
      }
      // Handle account creation error
    }
  };

  return (
    <div id="login">
      <Link to="/login">
        <img src={logo} alt="azora" className="logo" />
      </Link>

      <div className="login-container">
        <form onSubmit={handleCreateAccount}>
          <div className="form-group">
            <div className="btnGroup">
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                className='input'
              />
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                className='input'
              />
            </div>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className='input'
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className='input'
            />

            <div className="btnGroup">
              <button type="submit" className="btn primary">
                Create Account
              </button>
            </div>
          </div>
        </form>
      </div>

      <ToastContainer position="top-right" autoClose={3000} closeOnClick draggable />
    </div>
  );
}

export default Create;