import React, { useState } from 'react';
import './Login.scss';
import logo from '../../Images/widelogo.png';
import { Link } from 'react-router-dom';
import { auth } from '../../firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ResetPassword() {
  const [email, setEmail] = useState('');

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      toast.success('Password reset link sent to your email.');
      // Redirect or perform any success actions here
    } catch (error) {
      console.error(error);
      toast.error('Failed to send password reset email.');
    }
  };

  return (
    <div id="login">
      <Link to="/login">
        <img src={logo} alt="azora" className="logo" />
      </Link>

      <div className="reset-password-container">
        <form onSubmit={handleResetPassword}>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="input"
            />
            <button type="submit" className="btn primary">
              Reset Password
            </button>
          </div>
        </form>
      </div>

      <ToastContainer position="top-right" autoClose={3000} closeOnClick draggable />
    </div>
  );
}

export default ResetPassword;
