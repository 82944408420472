import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, setDoc } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBwGBf2v84TgA8P9b1vIQOpWIpetYbaaSo",
  authDomain: "azora-e2cce.firebaseapp.com",
  projectId: "azora-e2cce",
  storageBucket: "azora-e2cce.appspot.com",
  messagingSenderId: "1028318077392",
  appId: "1:1028318077392:web:b9a754e0428c5956db19d5",
  measurementId: "G-R59EHRET5G"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);

export { analytics, auth, db };
export default app;