import React, { useState, useEffect } from 'react';
import './Dashboard.scss';
import { Link, useLocation } from 'react-router-dom';
import { Home, Shield, Settings, Search } from 'react-feather';
import { collection, addDoc, query, where, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db, auth } from '../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import profile from '../../Images/profile.png'
import logo from '../../Images/widelogo.png'

function Dashboard() {
  const location = useLocation();
  const [monitorLink, setMonitorLink] = useState('');
  const [monitors, setMonitors] = useState([]);
  const [showLinksOverlay, setShowLinksOverlay] = useState(false);
  const [user, setUser] = useState(null);

  const handleAddLink = async () => {
    if (!monitorLink) {
      return;
    }

    try {
      if (user) {
        const monitorsCollectionRef = collection(db, 'monitors');
        await addDoc(monitorsCollectionRef, { link: monitorLink, userId: user.uid });
        setMonitorLink('');
        toast.success('Link added successfully.');
      }
    } catch (error) {
      console.error(error);
      toast.error('Failed to add link. Please try again.');
    }
  };

  const handleRemoveLink = async (monitorId) => {
    try {
      await deleteDoc(doc(db, 'monitors', monitorId));
      toast.success('Link removed successfully.');
    } catch (error) {
      console.error(error);
      toast.error('Failed to remove link. Please try again.');
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user); // Update the user state when the authentication state changes
    });

    return () => {
      unsubscribe(); // Unsubscribe from the onAuthStateChanged listener when the component unmounts
    };
  }, []);

  useEffect(() => {
    const fetchMonitors = async () => {
      try {
        if (user) {
          const monitorsCollectionRef = collection(db, 'monitors');
          const querySnapshot = await getDocs(query(monitorsCollectionRef, where('userId', '==', user.uid)));
          const monitorsData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          setMonitors(monitorsData);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchMonitors();
  }, [user]);

  return (
    <div className="dashboard-container">
      <div className="navbar">
        <div className='logoContainer'>
          <img src={logo} alt="Azora" className='logo' />
        </div>
        <ul className="menu">
          <li className={location.pathname === '/dashboard' ? 'active' : ''}>
            <Link to="/dashboard">
              <Home className="menu-icon" />
              Dashboard
            </Link>
          </li>
        </ul>
        <div className="profileContainer">
          <div className="profile-info">
            <img src={profile} alt="Profile" className="profile-picture" />
            <div className="account-info">
              <p className="account-name">Arkham NYC</p>
              <p className="plan-name">Premium Plan</p>
            </div>
          </div>
          <Link to="/settings" className='settingsIcon'>
            <Settings className="menu-icon" size={20} />
          </Link>
        </div>
      </div>
      <div className="dashboard">
        <div className="dashboard-header">
          <div className="search-bar">
            <Search className="search-icon" />
            <input
              type="text"
              placeholder="Enter Monitor URL (https://example.com/)"
              value={monitorLink}
              onChange={(e) => setMonitorLink(e.target.value)}
            />
          </div>
          <div className="dashboard-buttons">
            <button className="btn primary" onClick={handleAddLink}>
              Add Link
            </button>
            <button className="btn secondary" onClick={() => setShowLinksOverlay(true)}>
            Manage Links ({monitors.length}/25)
            </button>
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th>Image</th>
              <th>Item</th>
              <th>Time</th>
              <th>Price</th>
              <th>Variant</th>
            </tr>
          </thead>
          {/* <tbody>
            {monitors.map((monitor) => (
              <tr key={monitor.id}>
                <td>
                  <img src={monitor.image} alt="Item" className="item-image" />
                </td>
                <td>{monitor.name}</td>
                <td>{monitor.time}</td>
                <td>{monitor.price}</td>
                <td>{monitor.variant}</td>
              </tr>
            ))}
          </tbody> */}
        </table>
      </div>
      {showLinksOverlay && (
        <div className={`links-overlay show`}>
          <div className="links-container">
            <div className="links-header">
              <h2>Manage Links ({monitors.length}/25)</h2>
              <button className="close-btn" onClick={() => setShowLinksOverlay(false)}>
                Close
              </button>
            </div>
            <div className="links-list">
              {monitors.map((monitor) => (
                <div className="link-item" key={monitor.id}>
                  <p>{monitor.link}</p>
                  <button className="remove-btn" onClick={() => handleRemoveLink(monitor.id)}>
                    Remove
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <ToastContainer position="top-right" autoClose={3000} closeOnClick draggable />
    </div>
  );
}

export default Dashboard;
